body {
  background-color: black;
  font-family: 'Lato';
  font-size: 16px;

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 195;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: 0.6;
      pointer-events: initial;
    }
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}
