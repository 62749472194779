* {
  line-height: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style-type: none;
}

form {
  font-family: 'Lato';

  input {
    line-height: initial;
  }

  input,
  textarea,
  select {
    font-family: 'Lato';
    outline: none !important;

    &:active,
    &:focus {
      outline: none !important;
    }
  }
}

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  outline: none !important;
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

button,
button:active,
button:focus {
  font-family: 'Lato';
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0;
}

ul,
ol {
  margin: 0;
  list-style-type: none;
}

label {
  font-weight: normal;
}
