#main-menu {
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 45;

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }

  .close {
    position: absolute;
    top: 45px;
    right: 25px;

    svg path {
      fill: white;
    }
  }

  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .navigation {
      position: relative;

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          &:not(:last-child) {
            margin-bottom: 25px;
          }

          a {
            color: #595959;
            font-size: 21px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 2px;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &:hover {
              color: white;
            }
          }
        }
      }
    }

    .social {
      position: relative;
      margin-top: 35px;

      ul {
        display: flex;
        align-items: center;

        li {
          text-align: center;

          &:not(:last-child) {
            margin-right: 30px;
          }

          a {
            display: inline-block;

            svg path {
              fill: #595959;
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover svg path {
              fill: white;
            }
          }
        }
      }
    }
  }
}
