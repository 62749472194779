#main-logo {
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: initial;
  mix-blend-mode: screen;
  z-index: 50;

  @media (max-width: 1023px) {
    top: 25px;
    left: 25px;
    transform: unset;
  }
}

#main-menu-mobile {
  position: absolute;
  top: 45px;
  right: 25px;
  z-index: 40;
  width: 22px;
  height: 18px;

  @media (min-width: 1024px) {
    display: none;
  }

  span {
    background-color: white;
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    display: block;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 9px;
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }
}

#main-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;

  @media (max-width: 1023px) {
    display: none;
  }

  .navigation {
    position: fixed;
    top: 35px;
    left: 30px;
    transform-origin: 0% 0%;
    transform: rotate(-90deg) translate(-100%, 0);

    @media (max-width: 1023px) {
      left: -30px;
    }

    ul {
      display: flex;
      align-items: center;

      li {
        pointer-events: initial;

        @media (max-width: 1023px) {
          pointer-events: none;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }

        a {
          color: #595959;
          font-size: 12px;
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: 2px;
          transition-property: color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:hover {
            color: white;
          }
        }
      }
    }
  }

  .social {
    position: fixed;
    bottom: 35px;
    left: 30px;

    @media (max-width: 1023px) {
      left: -30px;
    }

    ul {
      li {
        pointer-events: initial;
        text-align: center;

        @media (max-width: 1023px) {
          pointer-events: none;
        }

        &:not(:last-child) {
          margin-bottom: 22px;
        }

        a {
          display: inline-block;

          svg path {
            fill: #595959;
            transition-property: fill;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &:hover svg path {
            fill: white;
          }
        }
      }
    }
  }
}
