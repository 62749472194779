/** Fancybox. */
.fancybox-slide {
  text-align: center;

  img {
    pointer-events: none;
  }
}

.fancybox-progress {
  background-color: white !important;
}

.fancybox-toolbar {
  display: flex;
  align-items: center;
  opacity: 1 !important;
  visibility: visible !important;

  button,
  a {
    background-color: white;
    width: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    transition: background-color time(default) ease(inout) !important;

    &:not(:last-child) {
      border-right: 1px solid black;
    }

    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
      padding: 0 !important;
    }

    &.disabled,
    &[disabled] {
      opacity: 0.3 !important;
    }

    svg {
      opacity: 1;
      width: 25px;
      height: 25px;

      path {
        fill: black;
        transition: fill time(default) ease(inout);
      }
    }

    &:hover {
      background-color: black;

      svg path {
        fill: white;
      }
    }
  }
}

.fancybox-content {
  background-color: transparent;
  padding: 0;
}

.fancybox-button--arrow_left {
  left: 0 !important;
  transform: translateY(-50%);

  /** Mobile. */
  @media (max-width: 1023px) {
    display: none !important;
  }
}

.fancybox-button--arrow_right {
  right: 0 !important;
  transform: translateY(-50%);

  /** Mobile. */
  @media (max-width: 1023px) {
    display: none !important;
  }
}

// .fancybox-button--play,
.fancybox-close-small {
  display: none !important;
}

.fancybox-button--arrow_left,
.fancybox-button--arrow_right {
  background-color: white !important;
  width: 60px !important;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 0 !important;
  transition: background-color time(default) ease(inout) !important;

  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
  }

  &.disabled,
  &[disabled] {
    opacity: 0.3 !important;
  }

  svg {
    opacity: 1;
    width: 26px !important;
    height: 26px !important;

    path {
      fill: black !important;
      transition: fill time(default) ease(inout);
    }
  }

  &:hover {
    background-color: black !important;

    svg path {
      fill: white !important;
    }
  }
}
