/**
* Variables.
*/
[rel='lazy_load']::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover; }

/**
* Base.
*/
body {
  background-color: black;
  font-family: 'Lato';
  font-size: 16px; }
  body::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 195;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms; }
  body.shadow {
    width: 100%;
    overflow: hidden; }
    body.shadow::before {
      opacity: 0.6;
      pointer-events: initial; }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }
  @media (max-width: 1300px) {
    .container {
      padding: 0 25px; } }

* {
  line-height: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style-type: none; }

form {
  font-family: 'Lato'; }
  form input {
    line-height: initial; }
  form input,
  form textarea,
  form select {
    font-family: 'Lato';
    outline: none !important; }
    form input:active, form input:focus,
    form textarea:active,
    form textarea:focus,
    form select:active,
    form select:focus {
      outline: none !important; }

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  outline: none !important; }

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }

button,
button:active,
button:focus {
  font-family: 'Lato';
  outline: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0; }

ul,
ol {
  margin: 0;
  list-style-type: none; }

label {
  font-weight: normal; }

.screen-progress {
  background-color: white;
  color: black;
  text-align: center;
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top 0.7s cubic-bezier(0.5, 0, 0, 1), opacity 0.7s cubic-bezier(0, 0, 0.3, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  .screen-progress.open {
    margin-top: 0;
    opacity: 1; }
  .screen-progress .screen-message {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 15px; }
  .screen-progress .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative; }

@keyframes progress_spinner {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: 0.3; }
  100% {
    transform: scale(1);
    opacity: 0; } }
    .screen-progress .spinner span {
      background-color: black;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite; }
      .screen-progress .spinner span:nth-child(2) {
        animation-delay: 0.5s; }

[rel='lazy_load'] {
  overflow: hidden; }

@keyframes lazy_load {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 50%; }
  50% {
    transform: scaleX(1);
    transform-origin: 0% 50%; }
  50.1% {
    transform: scaleX(1);
    transform-origin: 100% 50%; }
  100% {
    transform: scaleX(0);
    transform-origin: 100% 50%; } }
  [rel='lazy_load']::after {
    background-color: rgba(255, 255, 255, 0.5);
    content: '';
    transform: scaleX(0);
    z-index: 2; }
  [rel='lazy_load'] img {
    z-index: 1;
    transform-origin: 25% 25%;
    opacity: 0;
    transform: scale(1.05);
    transition-property: opacity, transform;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].loaded::after {
    animation-name: lazy_load;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].loaded img {
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.7s; }

/** Fancybox. */
.fancybox-slide {
  text-align: center; }
  .fancybox-slide img {
    pointer-events: none; }

.fancybox-progress {
  background-color: white !important; }

.fancybox-toolbar {
  display: flex;
  align-items: center;
  opacity: 1 !important;
  visibility: visible !important; }
  .fancybox-toolbar button,
  .fancybox-toolbar a {
    background-color: white;
    width: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
    transition: background-color 0.7s cubic-bezier(0.5, 0, 0, 1) !important; }
    .fancybox-toolbar button:not(:last-child),
    .fancybox-toolbar a:not(:last-child) {
      border-right: 1px solid black; }
    .fancybox-toolbar button div,
    .fancybox-toolbar a div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 !important;
      padding: 0 !important; }
    .fancybox-toolbar button.disabled, .fancybox-toolbar button[disabled],
    .fancybox-toolbar a.disabled,
    .fancybox-toolbar a[disabled] {
      opacity: 0.3 !important; }
    .fancybox-toolbar button svg,
    .fancybox-toolbar a svg {
      opacity: 1;
      width: 25px;
      height: 25px; }
      .fancybox-toolbar button svg path,
      .fancybox-toolbar a svg path {
        fill: black;
        transition: fill 0.7s cubic-bezier(0.5, 0, 0, 1); }
    .fancybox-toolbar button:hover,
    .fancybox-toolbar a:hover {
      background-color: black; }
      .fancybox-toolbar button:hover svg path,
      .fancybox-toolbar a:hover svg path {
        fill: white; }

.fancybox-content {
  background-color: transparent;
  padding: 0; }

.fancybox-button--arrow_left {
  left: 0 !important;
  transform: translateY(-50%);
  /** Mobile. */ }
  @media (max-width: 1023px) {
    .fancybox-button--arrow_left {
      display: none !important; } }

.fancybox-button--arrow_right {
  right: 0 !important;
  transform: translateY(-50%);
  /** Mobile. */ }
  @media (max-width: 1023px) {
    .fancybox-button--arrow_right {
      display: none !important; } }

.fancybox-close-small {
  display: none !important; }

.fancybox-button--arrow_left,
.fancybox-button--arrow_right {
  background-color: white !important;
  width: 60px !important;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  padding: 0 !important;
  transition: background-color 0.7s cubic-bezier(0.5, 0, 0, 1) !important; }
  .fancybox-button--arrow_left div,
  .fancybox-button--arrow_right div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important; }
  .fancybox-button--arrow_left.disabled, .fancybox-button--arrow_left[disabled],
  .fancybox-button--arrow_right.disabled,
  .fancybox-button--arrow_right[disabled] {
    opacity: 0.3 !important; }
  .fancybox-button--arrow_left svg,
  .fancybox-button--arrow_right svg {
    opacity: 1;
    width: 26px !important;
    height: 26px !important; }
    .fancybox-button--arrow_left svg path,
    .fancybox-button--arrow_right svg path {
      fill: black !important;
      transition: fill 0.7s cubic-bezier(0.5, 0, 0, 1); }
  .fancybox-button--arrow_left:hover,
  .fancybox-button--arrow_right:hover {
    background-color: black !important; }
    .fancybox-button--arrow_left:hover svg path,
    .fancybox-button--arrow_right:hover svg path {
      fill: white !important; }

/**
* Globals.
*/
#main-logo {
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: initial;
  mix-blend-mode: screen;
  z-index: 50; }
  @media (max-width: 1023px) {
    #main-logo {
      top: 25px;
      left: 25px;
      transform: unset; } }

#main-menu-mobile {
  position: absolute;
  top: 45px;
  right: 25px;
  z-index: 40;
  width: 22px;
  height: 18px; }
  @media (min-width: 1024px) {
    #main-menu-mobile {
      display: none; } }
  #main-menu-mobile span {
    background-color: white;
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    display: block; }
    #main-menu-mobile span:nth-child(1) {
      top: 0; }
    #main-menu-mobile span:nth-child(2) {
      top: 9px; }
    #main-menu-mobile span:nth-child(3) {
      bottom: 0; }

#main-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  pointer-events: none; }
  @media (max-width: 1023px) {
    #main-header {
      display: none; } }
  #main-header .navigation {
    position: fixed;
    top: 35px;
    left: 30px;
    transform-origin: 0% 0%;
    transform: rotate(-90deg) translate(-100%, 0); }
    @media (max-width: 1023px) {
      #main-header .navigation {
        left: -30px; } }
    #main-header .navigation ul {
      display: flex;
      align-items: center; }
      #main-header .navigation ul li {
        pointer-events: initial; }
        @media (max-width: 1023px) {
          #main-header .navigation ul li {
            pointer-events: none; } }
        #main-header .navigation ul li:not(:last-child) {
          margin-right: 20px; }
        #main-header .navigation ul li a {
          color: #595959;
          font-size: 12px;
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: 2px;
          transition-property: color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-header .navigation ul li a:hover {
            color: white; }
  #main-header .social {
    position: fixed;
    bottom: 35px;
    left: 30px; }
    @media (max-width: 1023px) {
      #main-header .social {
        left: -30px; } }
    #main-header .social ul li {
      pointer-events: initial;
      text-align: center; }
      @media (max-width: 1023px) {
        #main-header .social ul li {
          pointer-events: none; } }
      #main-header .social ul li:not(:last-child) {
        margin-bottom: 22px; }
      #main-header .social ul li a {
        display: inline-block; }
        #main-header .social ul li a svg path {
          fill: #595959;
          transition-property: fill;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-header .social ul li a:hover svg path {
          fill: white; }

#main-menu {
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  z-index: 45; }
  #main-menu.open {
    opacity: 1;
    visibility: visible;
    pointer-events: initial; }
  #main-menu .close {
    position: absolute;
    top: 45px;
    right: 25px; }
    #main-menu .close svg path {
      fill: white; }
  #main-menu .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    #main-menu .wrapper .navigation {
      position: relative; }
      #main-menu .wrapper .navigation ul {
        display: flex;
        flex-direction: column;
        align-items: center; }
        #main-menu .wrapper .navigation ul li:not(:last-child) {
          margin-bottom: 25px; }
        #main-menu .wrapper .navigation ul li a {
          color: #595959;
          font-size: 21px;
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: 2px;
          transition-property: color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .wrapper .navigation ul li a:hover {
            color: white; }
    #main-menu .wrapper .social {
      position: relative;
      margin-top: 35px; }
      #main-menu .wrapper .social ul {
        display: flex;
        align-items: center; }
        #main-menu .wrapper .social ul li {
          text-align: center; }
          #main-menu .wrapper .social ul li:not(:last-child) {
            margin-right: 30px; }
          #main-menu .wrapper .social ul li a {
            display: inline-block; }
            #main-menu .wrapper .social ul li a svg path {
              fill: #595959;
              transition-property: fill;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .wrapper .social ul li a:hover svg path {
              fill: white; }
